
import { Component, Vue } from 'vue-property-decorator';
import {
  readEquipmentHealth, readEquipment,
} from '@/store/equipments/getters';
import {
  dispatchGetEquipmentHealthData, dispatchGetEquipment
} from '@/store/equipments/actions';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';

@Component({
  components: {
    highchart: Chart,
  },
})
export default class EquipmentHealth extends Vue {

  get update_time() {
    return this.latest_health_data ? this.latest_health_data.datetime + "Z" : "Desconhecido";
  }

  get battery() {
    return this.latest_health_data ? this.latest_health_data.battery : NaN;
  }

  get pitch() {
    return this.latest_health_data ? this.latest_health_data.pitch : NaN;
  }

  get roll() {
    return this.latest_health_data ? this.latest_health_data.roll : NaN;
  }

  get equipmentHealth() {
    return readEquipmentHealth(this.$store);
  }

  get equipment() {
    return readEquipment(this.$store);
  }

  get latest_health_data() {
    return this.equipmentHealth.current.at(0);
  }

  async mounted() {
    const equipmentId: number = parseInt(this.$route.params.id, 10);
    await dispatchGetEquipment(this.$store, equipmentId);
    if (this.equipment && this.equipment.name.includes('AWAC')) {
      await dispatchGetEquipmentHealthData(this.$store, equipmentId);
    }
  }

  get equipmentHealthTimeSeriesChartOptions() {
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        offset: 40,
      },
      yAxis: [
        {
          title: {
            text: 'Bateria',
            style: {
              color: '#004765',
            },
          },
          labels: {
            format: '{value} V',
            style: {
              color: '#004765',
            },
          },
        },
        {
          title: {
            text: 'Pitch',
            style: {
              color: '#8cb428',
            },
          },
          labels: {
            format: '{value} °',
            style: {
              color: '#8cb428',
            },
          },
          opposite: true,
        },
        {
          title: {
            text: 'Roll',
            style: {
              color: '#fca31c',
            },
          },
          labels: {
            format: '{value} °',
            style: {
              color: '#fca31c',
            },
          },
          opposite: true,
        },
      ],
      series: [
        {
          type: 'line',
          data: this.equipmentHealth.current.map((e) => [new Date(e.datetime + "Z").valueOf(), e.battery]),
          name: 'Bateria',
          color: '#004765',
          tooltip: {
            valueDecimals: 1,
            valueSuffix: ' V',
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        {
          type: 'line',
          name: 'Pitch',
          color: '#8cb428',
          yAxis: 1,
          data: this.equipmentHealth.current.map((e) => [new Date(e.datetime + "Z").valueOf(), e.pitch]),
          tooltip: {
            valueDecimals: 1,
            valueSuffix: ' °',
          },
        },
        {
          type: 'line',
          name: 'Roll',
          color: '#fca31c',
          yAxis: 1,
          data: this.equipmentHealth.current.map((e) => [new Date(e.datetime + "Z").valueOf(), e.roll]),
          tooltip: {
            valueDecimals: 1,
            valueSuffix: ' °',
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

}
