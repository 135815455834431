
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import WindDirectionChart from '@/components/WindDirectionChart.vue';
import WaveDirectionChart from '@/components/WaveDirectionChart.vue';
import CurrentDirectionChart from '@/components/CurrentDirectionChart.vue';
import {
  readEquipment,
  readLoadedData,
  readAvailableParams,
  readLatestData,
  readChartData,
} from '@/store/equipments/getters';
import { readUserRoles } from '@/store/main/getters';
import {
  dispatchGetEquipment,
  dispatchDeleteEquipmentData,
  dispatchGetEquipmentData,
  dispatchUnsetLoadedData,
  dispatchUpdateData,
} from '@/store/equipments/actions';
import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';
import WindbarbHighcharts from 'highcharts/modules/windbarb';
import { Chart } from 'highcharts-vue';
import { getLocalToken } from '@/utils';
import { apiWSUrl } from '@/env';
import Maintenance from '@/components/Maintenance.vue';
import EquipmentHealth from '@/components/EquipmentHealth.vue';
import { ICurrentData, ITideData, IWaveData, IWeatherData, IWindData } from '@/interfaces';

const connect = (ctx) => {

  const ws = new WebSocket(`${apiWSUrl}/api/v1/data/ws?token=${ctx.token}`);

  ws.onmessage = async (event) => {
    const updatedData = {}
    const receivedMessage: IWindData | IWeatherData | ICurrentData | IWaveData | ITideData = JSON.parse(event.data)
    const eqpId = Object.values(receivedMessage)[0].equipment_id
    updatedData[eqpId] = receivedMessage
    if (ctx.equipment) {
      await dispatchUpdateData(ctx.$store, {
        equipment_id: ctx.equipment.id,
        data: updatedData,
      });
    }
  };

  ws.onclose = (e) => {
    setTimeout(() => connect(ctx), 1000);
  };

  ws.onerror = (e) => {
    ws.close();
  };

  return ws;
};

hcMore(Highcharts);
WindbarbHighcharts(Highcharts);

@Component({
  components: {
    highchart: Chart,
    WindDirectionChart,
    WaveDirectionChart,
    CurrentDirectionChart,
    Maintenance,
    EquipmentHealth,
  },
})
export default class Equipments extends Vue {
  public headers = [
    {
      text: 'Identifier',
      sortable: true,
      value: 'identifier',
      align: 'left',
    },
    {
      text: 'Equipment Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Station',
      sortable: true,
      value: 'station',
      align: 'left',
    },
  ];

  private connection;

  get waveTimeSeriesChartOptions() {
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
        offset: 40,
      },
      yAxis: [
        {
          title: {
            text: 'Altura de onda significativa',
            style: {
              color: '#004765',
            },
          },
          labels: {
            format: '{value} m',
            style: {
              color: '#004765',
            },
          },
        },
        {
          title: {
            text: 'Período de pico',
            style: {
              color: '#8cb428',
            },
          },
          labels: {
            format: '{value} s',
            style: {
              color: '#8cb428',
            },
          },
          opposite: true,
        },
      ],
      series: [
        {
          type: 'line',
          name: 'Período de pico',
          color: '#8cb428',
          yAxis: 1,
          data: this.chartData.wave.peak_wave_period,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' s',
          },
        },
        {
          type: 'windbarb',
          data: this.chartData.wave.peak_wave_direction,
          name: 'Direção de pico',
          color: 'black',
          showInLegend: false,
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">●</span> {series.name}: <b>{point.direction}</b><br/>',
            valueSuffix: ' °',
            valueDecimals: 2,
          },
        },
        {
          type: 'line',
          data: this.chartData.wave.significant_wave_height,
          name: 'Altura de onda significativa',
          color: '#004765',
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' m',
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get currentTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
        offset: 40,
      },
      yAxis: {
        labels: {},
        title: {
          text: 'Velocidade (nós)',
        },
      },
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Velocidade da corrente',
          data: this.chartData.current.current_speed,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' nós',
          },
        },
        {
          type: 'windbarb',
          data: this.chartData.current.current_direction,
          name: 'Direção',
          color: 'black',
          showInLegend: false,
          tooltip: {
            pointFormatter(this: any) {
              const value =
                this.direction !== undefined
                  ? Math.round(((this.direction + 180) % 360) * 100) / 100
                  : NaN;
              return (
                '<span style="color:' +
                this.color +
                '">●</span> Direção: <b>' +
                value +
                ' °</b><br/>'
              );
            },
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get windTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
        offset: 40,
      },
      yAxis: {
        labels: {},
        title: {
          text: 'Velocidade (nós)',
        },
      },
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Velocidade do vento',
          data: this.chartData.wind.wind_speed,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' nós',
          },
        },
        {
          type: 'line',
          color: '#8cb428',
          name: 'Velocidade de rajada',
          data: this.chartData.wind.gust_speed,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' nós',
          },
        },
        {
          type: 'windbarb',
          data: this.chartData.wind.wind_direction,
          name: 'Direção',
          color: 'black',
          showInLegend: false,
          tooltip: {
            pointFormat:
              '<span style="color:{point.color}">●</span> {series.name}: <b>{point.direction}</b><br/>',
            valueSuffix: ' °',
            valueDecimals: 2,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get visibilityTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: [
        {
          title: {
            text: 'Visibilidade',
          },
          labels: {
            format: '{value:.1f} km',
          },
        },
        {
          linkedTo: 0,
          opposite: true,
          labels: {
            formatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
              const n: number =
                typeof this.value === 'string' ? parseFloat(this.value) : this.value;
              return String(Math.round((n / 1.852) * 10) / 10) + ' nm';
            },
          },
          title: {
            text: null,
          },
        },
      ],
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Visibilidade',
          data: this.chartData.weather.visibility,
          tooltip: {
            pointFormatter() {
              const valueInKM = this.y === undefined ? NaN : Math.round(this.y * 10) / 10;
              const valueInNM = Math.round((valueInKM / 1.852) * 10) / 10;
              return valueInKM + ' km (' + valueInNM + ' nm)';
            },
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get precipitationTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Precipitação (mm)',
        },
      },
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Precipitação',
          data: this.chartData.weather.precipitation,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' mm',
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get waterTempTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Temperatura da água (°C)',
        },
      },
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Temperatura da água',
          data: this.chartData.weather.water_temperature,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' °C',
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get waterLevelTimeSeriesChartOptions() {
    const ctx = this;
    const chartConfig: Highcharts.Options = {
      title: {
        text: undefined,
      },
      time: {
        useUTC: false,
      },
      tooltip: {
        dateTimeLabelFormats: {
          minute: '%d/%m/%Y %H:%M',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Nível da água (m)',
        },
      },
      series: [
        {
          type: 'line',
          color: '#004765',
          name: 'Nível da água',
          data: this.chartData.tide.water_level,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' m',
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return chartConfig;
  }

  get latestData() {
    return readLatestData(this.$store);
  }

  get equipment() {
    return readEquipment(this.$store);
  }

  public get userRoles() {
    return readUserRoles(this.$store);
  }

  public get userIsOperator() {
    return ['OPERATOR', 'ADMIN'].some((r) => this.userRoles!.includes(r))
  }

  get chartData() {
    return readChartData(this.$store);
  }

  get loadedData() {
    return readLoadedData(this.$store);
  }

  get availableParams() {
    return readAvailableParams(this.$store);
  }

  get token() {
    return getLocalToken();
  }

  public beforeMount() {
    dispatchDeleteEquipmentData(this.$store);
    dispatchUnsetLoadedData(this.$store);
  }

  public async mounted() {
    const equipmentId: number = parseInt(this.$route.params.id, 10);
    await dispatchGetEquipment(this.$store, equipmentId);

    await dispatchGetEquipmentData(this.$store, this.equipment);
  }

  public async created() {
    const ctx = this;
    this.connection = connect(ctx);
  }

  public async beforeRouteLeave(to, from, next) {
    this.connection.close();
    next();
  }
}
